<template>
  <div class="main container product-page">
    <Breadcrumb :list="breadcrumbList" />
    <div class="content">
      <div class="left">
        <h2 class="title">架构</h2>
        <p class="animate__animated animate__fadeIn">
          ShuangzhaoDB可以采用多种部署方式进行部署：内部部署，集成了Kubernetes的云部署（虚拟化和容器化）。
        </p>
      </div>
      <ProdMenu class="right hidden-sm-and-down" activeIndex="5" />
    </div>
    <el-image
      class="animate__animated animate__fadeIn"
      :src="require('@/assets/images/architecture.png')"
      fit="cover"
      :preview-src-list="[require('@/assets/images/architecture.png')]"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ProdMenu from "@/components/ProdMenu";
import "animate.css";
export default {
  name: "Architecture",
  components: {
    Breadcrumb,
    ProdMenu,
  },
  data() {
    return {
      breadcrumbList: [
        { id: 1, path: "/", label: "ShuangzhaoDB" },
        { id: 2, path: "/product", label: "产品" },
        { id: 3, path: "", label: "架构" },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/product.less";
.el-image {
  margin-top: 50px;
}
@media screen and(max-width:991px) {
  .el-image {
    margin-top: 16px;
  }
}
</style>